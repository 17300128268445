.google-form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    width: clamp(350px, 75%, 600px);
    height: clamp(300px, 85%, 1200px);

    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;

    /* pop-in animation */
    animation: pop-in 0.5s ease-out;

    .google-form {
        width: 100%;
        height: 90%;
        margin: 0;
        border: 0;
    }

    .window-bar {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;

        padding: 10px 10px;


        background-color: white;
        border-radius: 10px 10px 0 0;

        svg {
            cursor: pointer;
        }
    }
}

.click-catcher {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    z-index: 101;
}

@keyframes pop-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}