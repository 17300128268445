

.landing-section {
    height: calc(100vh - 80px);
    width: 100%;

    background-image: url('../../../../../public/img/beggerslyingsidestreet.png');
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
}

.landing-section h1 {
    font-size: clamp(1.5rem, 10vw, 3.5rem);
    font-weight: 600;
    margin-bottom: 20px;

    color: var(--color-primary);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); /* make the text more visible against the picture */
}

.landing-section a {
    text-align: center;
    width: 55%;

    /* font-size: 1.5rem; */
    font-size: clamp(1rem, 5vw, 1.5rem);
    line-height: 1.5;
}

/* on small screens increase the width to 85% */
@media screen and (max-width: 768px) {
    .landing-section a {
        width: 85%;
    }
}