.team-member-card {
    min-height: 275px;
    max-height: 325px;
    height: 100vh;

    width: 250px;

    display: table;


    border-radius: 5px;
    background-color: white;
}

.team-member-card img {
    width: 250px;
    height: 250px;

    /* change how the image scales */
    object-fit: cover;
    object-position: center;

    display: table-row;

    border-radius: 5px 5px 0 0;
}

.team-member-card .name-plate {
    display: table-row;
    height: 100%;
}

.text-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.team-member-card p {
    margin: 0;  
    padding: 0;

    display: table-cell;
    vertical-align: middle;
    text-align: center;

    color: black;
    font-size: 1.5em;
    font-weight: 600;
}