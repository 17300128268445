.gf-component-wrapper {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}

.events-page {
    height: 1000px;
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        color: var(--color-primary);
        font-size: clamp(2.5em, 5vw, 3em);
        text-decoration: underline;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .styled-calendar-container {
        border: none;
        width: min(100%, 100vh);
        height: 90%;
    }


    button {
        margin-top: 25px; /* space between the members and the button */
    
        padding: 15px 35px;
        background-color: var(--color-primary);
        
        border-radius: 5px;
        font-size: 1.2em;
    
        border: none;
        color: white;
        cursor: pointer;

        margin-bottom: 30px;
    }
    
    button:hover {
        background-color: var(--color-primary-dark);
        transition: background-color 0.3s;
    }
    
    button:active {
        transform: translateY(2px);
    }
}