.event-card {
    width: min(400px, 100vw - 50px);
    height: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    img {
        width: 100%;
        height: 275px;
        object-fit: cover;

        /* make the image appear just white */
        opacity: 0;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .date-container {
        position: absolute;
        
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
        width: 60px;
        height: 60px;
        margin: 20px;
        
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;


        p {
            margin: 0;
            padding: 0;
            color: black;
        }

        p:first-child {
            font-size: 1.5rem;
            font-weight: 700;
        }

        p:last-child {
            font-size: 0.8rem;
            font-weight: 400;
        }
    }

    .description {
        h1 {
            margin: 0;
            padding: 10px;
            color: black;
            font-size: clamp(1rem, 2vw, 1.45rem);
            text-decoration: none;
        }

        .sub-details {

            .sub-detail {
                height: fit-content;

                display: flex;
                justify-content: start;
                gap: 10px;
                align-items: center;
                padding: 10px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);

                p {
                    margin: 0;
                    padding: 0;
                    color: black;
                }
            }
        }
    }
}