.info-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;

  height: 100%;

  padding: 50px 0;

  background-color: #254151;
}

.info-footer .col {
    width: 350px;
    height: 100%;
}

.info-footer .col h1 {
    margin-top: 0px;
    margin-bottom: 60px;
}


/* Third Column Stuff */
.info-footer .col-3 ul {
    list-style-type: none;
    padding: 0;
}

.info-footer .col-3 h1 {
    color: white;
    font-size: 24px;
    font-weight: 700;
}

.info-footer .contact-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
}

.info-footer .contact-option .contact-info {
    margin-left: 10px;
}

.info-footer .contact-option svg {
    font-size: 26px;
    color: var(--color-primary);
    margin-right: 15px;

    width: 30px;
    height: 30px;
}

.info-footer .contact-option .contact-info h2 {
    color: lightgrey;
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 5px;
}

.info-footer .contact-option .contact-info a {
    color: white;
    font-size: 16px;
    font-weight: 700;
}