
.events-section {
    /* min-height: 800px; */
    width: 100%;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    h1 {
        font-size: clamp(2em, 10vw, 3em);
        text-decoration: underline;
        color: var(--color-primary);
        margin-bottom: 35px;
    }

    .events-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
    }

    button {
        margin-top: 25px; /* space between the members and the button */
    
        padding: 15px 35px;
        background-color: var(--color-primary);
        
        border-radius: 5px;
        font-size: 1.2em;
    
        border: none;
        color: white;
        cursor: pointer;
    }
    
    button:hover {
        background-color: var(--color-primary-dark);
        transition: background-color 0.3s;
    }
    
    button:active {
        transform: translateY(2px);
    }
}