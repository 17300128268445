.back-to-top-button {
    /* should be 10px off the bottom right corner no matter how far we've scrolled */
    position: fixed;
    bottom: 10px;
    right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    background-color: var(--color-primary);
    border-radius: 5px;

    display: none;
    transition: opacity 0.3s;
    color: lightgrey;

    svg {
        width: 48px;
        height: 48px;
    }
}