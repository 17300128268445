.navbar {
    height: 70px;
    width: 100%;

    background-color: black;
    padding: 5px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar .horiz-nav-bar {
    width: 100%; /* Remove this to center the elements */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;
    margin: 0;
    list-style: none;
}

.navbar .horiz-nav-bar li {
    padding: 20px;
}

.navbar .horiz-nav-bar li a {
    text-decoration: none;
    color: white;
    font-weight: 550;

    cursor: pointer;
}

.navbar .horiz-nav-bar li a:hover {
    color: var(--color-primary) !important;
    text-decoration: underline;
    text-underline-offset: 15px;
    text-decoration-thickness: 3px;
}


/* Home Button */
.navbar .home-button {
    background-color: var(--color-primary);
    border-radius: 35px;
    height: fit-content;
    padding: 15px 20px;
    cursor: pointer;
}

.navbar .home-button a {
    color: white;
    font-weight: 550;
}

.navbar .home-button svg {
    color: white;
    margin-right: 10px;
}

/* responsive */
@media screen and (max-width: 970px) {
    .navbar {
        .nav-item {
            display: none;
        }
    
        .home-button {
            display: none;
        }
    
        .vert-nav-item {
            display: flex !important;
            font-size: 1.5rem;
        }

        .vert-nav-item:active {
            transform: scale(1.1);
        }
    
        .horiz-nav-bar {
            justify-content: end;
        }
    }
}

.navbar .vert-nav-item {
    display: none;
}

/* vert nav bar */
.navbar {
    .horiz-nav-bar {
        z-index: 100;
    }

    .vert-nav-bar {
        display: none;
        width: 75%;

        position: absolute;
        top: 0;
        right: 0;

        padding: 0;
        padding-top: 80px;
        margin: 0;

        height: 100%;
        
        background-color: black;
    }
    
    .show {
        display: inline !important;
        /* slide in animation */
        animation: slide-in 0.5s forwards;
    }

    .vert-nav-bar li {
        padding: 20px;
        display: flex;
        justify-content: start;
        align-items: center;

    }
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}