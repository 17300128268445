.who-we-are {
    .main-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-top: 25px;
    }

    .sub-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 20px;
    }

    .sub-section p {
        text-align: center;
        max-width: 800px;
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;

        margin-bottom: 50px;
    }

    .images img {
        max-width: 90vw;
        box-shadow: 0 0 10px 5px rgba(209, 101, 135, 0.35);
    }

    h1 {
        color: var(--color-primary);
        font-size: clamp(2.5em, 5vw, 3em);
        text-decoration: underline;
    }

    /* nice looking buttons */
    button {
        margin-bottom: 300px;

        padding: 15px 35px;
        background-color: var(--color-primary);
        
        border-radius: 5px;
        font-size: 1.2em;
    
        border: none;
        color: white;
        cursor: pointer;
    }

    button:hover {
        transition: background-color 0.3s;
        background-color: var(--color-primary-dark);
    }

    button:active {
        transform: translateY(2px);
    }

    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100%;
        background-color: #F5F5F5;
    }

    .section:nth-child(odd) {
        background-color: white;
    }


    /* our team section */
    .team-member-card {
        /* box shadow to make it pop from the white background since the card is white */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .team-display {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        width: min(100%, 1070px);

        margin-bottom: 40px;
    }
}