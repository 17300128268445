.our-team-section {
    background-color: white;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px 0;
}

.our-team-section .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Header Text Stuff */
.our-team-section .header-text {
    text-align: center;
    width: 55%;
}

.our-team-section .header-text h1 {
    font-size: clamp(2em, 10vw, 3em);
    text-decoration: underline;
    color: var(--color-primary);
}

.our-team-section .header-text p {
    font-size: clamp(1em, 2vw, 1.5em);
    font-weight: 250;
    line-height: 1.5;
}

/* on small screens increase the width to 85% */
@media screen and (max-width: 768px) {
    .our-team-section .header-text {
        width: 85%;
    }
}

/* Member Stuff */
.our-team-section .member-container {
    margin-top: 40px;

    /* flex row display that overflows into a column */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    /* spacing between the elements */
    gap: 20px;

    width: 80%;
}

.our-team-section .team-member-card {
    /* box shadow to make it pop from the white background since the card is white */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* View More Button */
.our-team-section .view-more-button {
    margin-top: 25px; /* space between the members and the button */

    padding: 15px 35px;
    background-color: var(--color-primary);
    
    border-radius: 5px;
    font-size: 1.2em;

    border: none;
    color: white;
    cursor: pointer;
}

.our-team-section .view-more-button:hover {
    background-color: var(--color-primary-dark);
    transition: background-color 0.3s;
}

.our-team-section .view-more-button:active {
    transform: translateY(2px);
}