.support-page {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 300px;

    .title {
        color: var(--color-primary);
        font-size: clamp(2.5em, 5vw, 3em);
        text-decoration: underline;
        margin-bottom: 30px;
        margin-top: 0px;
        text-align: center;
    }
}

.support-page .sections {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.support-page .sections .section {
    background-color: #F5F5F5;
    padding: 20px;
}

.support-page .sections .section:nth-child(even) {
    background-color: white;
}

.support-page .sections .section h1 {
    font-size: 2.5rem;
    color: var(--color-primary);
}

/* volunteer section */
.support-page .section-volunteer {
    .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .content .description {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        max-width: clamp(100px, 100vw, 500px);
    }

    img {
        height: clamp(0px, 60vh, 500px);
    }
}

/* fundraising */
.support-page .section-fundrasing {
    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        max-width: clamp(100px, 100vw, 500px);
    }

    img {
        width: clamp(320px, 40vw, 550px);
    }
}



/* donations section */
.support-page .sections .section-donate h1 {
    text-align: center;
    margin-bottom: 40px;
}

.support-page .sections .section-fundrasing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.support-page .sections .section-fundrasing .content {
    max-width: clamp(100px, 100vw, 500px);
}


/* Donate section */
.support-page .sections .section-donate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        max-width: clamp(100px, 100vw, 500px);
    }
}

.support-page .sections .section-donate p {
    max-width: clamp(100px, 100vw, 500px);
}

.support-page .sections .section-donate ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0;
    width: clamp(200px, 100vw, 350px);
}

.support-page .sections .section-donate ul li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    width: 100%;
}

.support-page .sections .section-donate ul li:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    animation: fade-to-color 0.3s forwards;
}

/* fade to color animation */
@keyframes fade-to-color {
    0% {
        background-color: white;
    }
    100% {
        background-color: var(--color-primary);
    }
}